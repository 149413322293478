<template>
  <div>
    <v-dialog v-model="ModalEdit" persistent scrollable max-width="50%">
      <v-card>
        <v-card-title> Edit Unit </v-card-title>

        <div class="px-6 mt-n4">
          <strong class="caption">
            {{ SData.nama }}
          </strong>
        </div>

        <v-divider class="mt-2 mb-5"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row dense class="mt-1">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="SData.idBKN"
                  label="ID Unor BKN"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-textarea
              class="mt-1"
              v-model="SData.nama"
              label="Nama Unit"
              outlined
              dense
            ></v-textarea>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="SData.telepon"
                  label="Telepon"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="SData.email"
                  label="E-mail"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="SData.alamat"
                  label="Alamat"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="SData.longitude"
                  label="Longitude"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="SData.latitude"
                  label="Latitude"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-select
              v-model="SData.status"
              label="Status"
              :items="items"
              outlined
              dense
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="gray" @click="closeModal()"> Batal </v-btn>
          <v-btn color="primary" @click="edit()" v-if="LBtn"> Simpan </v-btn>
          <v-btn color="primary" loading v-else> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import SView from "@/store/unor/view";
import SEdit from "@/store/unor/edit";

export default {
  computed: {
    ModalEdit: {
      get() {
        return SEdit.state.ModalEdit;
      },
      set(value) {
        SEdit.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SEdit.state.data;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    LBtn: true,

    items: ["active", "inactive"],
    status: "",
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async edit() {
      this.LBtn = false;
      let data = {
        id: this.SData.id,
        parent: this.SData.atasan,
        nama: this.SData.nama,
        telepon: this.SData.telepon,
        email: this.SData.email,
        alamat: this.SData.alamat,
        longitude: this.SData.longitude,
        latitude: this.SData.latitude,
        status: this.SData.status,
      };

      const url = process.env.VUE_APP_API + "unor/edit";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LBtn = true;
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LBtn = true;
          }

          this.closeModal();
        });
    },

    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.ModalEdit = false;
    },
  },
};
</script>
